<template>
  <div class="shop">
    <Search navType="search" :sty="topSty"></Search>
    <TopCon></TopCon>
    <Nav :initDataNav="initData"></Nav>
    <advertising></advertising>
    <LastMinutes></LastMinutes>
    <HotAct></HotAct>
    <ShopList></ShopList>
    <StoreList :storeList="storeInfo.arr" :showTabs="false"></StoreList>
  </div>
</template>

<script>
import HotAct from "./HotAct";
import ShopList from "./ShopList";
import TopCon from "../bannerTop";
import LastMinutes from "./LastMinutes";
import Nav from "@components/public/nav";
import Store from "@/api/store";
import Search from "@components/public/searchTop";
import StoreList from "@components/public/storeList";
import advertising from "@components/public/advertising"
export default {
  components: {
    Search, TopCon, Nav, LastMinutes, HotAct, ShopList, advertising, StoreList
  },
  data() {
    return {
      topSty: {
        background: "linear-gradient(-90deg,rgba(255, 128, 0, 1),rgba(255, 174, 0, 1))",
        color: '#ffffff',
        zIndex: 1000
      },
      storeInfo: {
        arr: []
      },
      bottomData: [
        {
          title :"首页",
          ico: "iconfont iconhome",
          path: "/shopindex"
        },
        {
          title: "分类",
          ico: "iconfont iconxiaoxi",
          path: "/goods"
        },
        {
          title: "订单",
          ico: "iconfont icongouwuche1",
          path: "/order"
        },
        {
          title: "我的",
          ico: "iconfont iconwode",
          path: "/personal"
        }
      ],
      initData: [
        {
          id: 0,
          pic: require("../../../../public/static/images/home/tuangou.png"),
          title: "女装",
          url: "/groupbuy"
        },
        {
          id: 1,
          pic: require("../../../../public/static/images/home/shangcheng.png"),
          title: "家电",
          url: "/enjoyshop"
        },
        {
          id: 2,
          pic: require("../../../../public/static/images/home/qiangou.png"),
          title: "手机",
          url: ""
        },
        {
          id: 3,
          pic: require("../../../../public/static/images/home/miandan.png"),
          title: "鞋包",
          url: ""
        },
        {
          id: 4,
          pic: require("../../../../public/static/images/home/kanjia.png"),
          title: "电脑",
          url: ""
        },
        {
          id: 5,
          pic: require("../../../../public/static/images/home/qiandao.png"),
          title: "母婴",
          url: ""
        },
        {
          id: 6,
          pic: require("../../../../public/static/images/home/zhuanpan.png"),
          title: "美妆",
          url: ""
        },
        {
          id: 7,
          pic: require("../../../../public/static/images/home/youli.png"),
          title: "男装",
          url: ""
        },
        {
          id: 8,
          pic: require("../../../../public/static/images/home/lingquan.png"),
          title: "入驻",
          url: "/businezhussesin"
        },
        {
          id: 9,
          pic: require("../../../../public/static/images/home/shangjiaruzhu.png"),
          title: "全部",
          url: "/goods"
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getStoreListArr();
    })
  },
  methods: {
    async getStoreListArr() {
      try {
        const res = await Store.getStoreList(1);
        if (res.code == 200) {
          this.storeInfo.arr = res.data; 
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.shop {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #f4f4f4;
}
</style>
