<template>
  <div class="advertsing">
    <img src="@static/home/avert.png" alt="">
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.advertsing {
  width: 100%;
  height: auto;
  padding: 0px 10px;
  img{
    width: 100%;
  }
}
</style>
