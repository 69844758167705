<template>
  <div class="hot-act">
    <div class="hot-act-title">
      <span>热门活动</span>
    </div>
    <div class="hot-act-list">
      <div class="hot-act-list-item" v-for="(itemm, idx) in 8" :key="idx">
        <div class="hot-act-list-top">
          <img src="@static/home/cp.png" alt />
        </div>
        <div class="hot-act-list-bot">
          <span>茵曼箱包</span>
          <div class="hot-name">爆款五折起</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.hot-act {
  width: 100%;
  height: auto;
  .hot-act-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    background: url(../../../../public/static/images/home/title-bg.png) no-repeat;
    background-size: 40%;
    background-position-y: 50%;
    background-position-x: 50%;
  }
  .hot-act-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-top: -12.5px;
    justify-content: space-between;
    .hot-act-list-item {
      width: 24%;
      height: auto;
      margin-top: 12.5px;
      background: #ffffff;
      .hot-act-list-top {
        width: 100%;
        height: 80px;
        padding: 9px 6px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .hot-act-list-bot {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        color: #ffffff;
        padding-top: 4px;
        padding-bottom: 6px;
        background: linear-gradient(
          -90deg,
          rgba(171, 6, 229, 1) 0%,
          rgba(222, 89, 246, 1) 100%
        );
        .hot-name {
          width: auto;
          height: 16px;
          font-size: 10px;
          line-height: 16px;
          background: #ffffff;
          color: #a328e0;
          text-align: center;
          border-radius: 12px;
          font-weight: 500;
          margin-top: 5px;
          padding: 0px 6px;
        }
      }
    }
  }
}
</style>
