
<template>
  <div class="lastbuy">
    <div class="lastbuy-item">
      <div class="hot-goods">
        <span>抢购</span>
        <div class="hot-goods-time">
          <div>10点场</div>9:50:52
        </div>
      </div>
      <div class="lastbuy-item-left-con">
        <div class="lastbuy-bot-item">
          <div class="lastbuy-bot-item-img">
            <img src="@static/home/cp.png" alt />
          </div>
          <span class="iconfont iconrenminbi_o">
            <span class="newPay">5000</span>
          </span>
          <span class="iconfont iconrenminbi_o oldPay">8000</span>
        </div>
        <div class="lastbuy-bot-item">
          <div class="lastbuy-bot-item-img">
            <img src="@static/home/cp.png" alt />
          </div>
          <span class="iconfont iconrenminbi_o">
            <span class="newPay">5000</span>
          </span>
          <span class="iconfont iconrenminbi_o oldPay">8000</span>
        </div>
        <div class="lastbuy-bot-item">
          <div class="lastbuy-bot-item-img">
            <img src="@static/home/cp.png" alt />
          </div>
          <span class="iconfont iconrenminbi_o">
            <span class="newPay">5000</span>
          </span>
          <span class="iconfont iconrenminbi_o oldPay">8000</span>
        </div>
      </div>
    </div>
    <div class="lastbuy-bot-item-two" style="padding-left:6px">
      <div class="lastbuy-bot-item-title">
        <span class="lastbuy-bt">大牌惠购</span>
        <br />
        <span class="lastbuy-btpay">汇大牌，享好价</span>
      </div>
      <div class="lastbuy-bot-item-two-img">
        <img src="@static/home/cp.png" alt />
        <img src="@static/home/cp.png" alt />
      </div>
    </div>
    <div class="lastbuy-bot-item-two">
      <div class="lastbuy-bot-item-title">
        <span class="lastbuy-bt">大牌惠购</span>
        <br />
        <span class="lastbuy-btpay">汇大牌，享好价</span>
      </div>
      <div class="lastbuy-bot-item-two-img">
        <img src="@static/home/cp.png" alt />
        <img src="@static/home/cp.png" alt />
      </div>
    </div>
    <div class="lastbuy-bot-item-two">
      <div class="lastbuy-bot-item-title">
        <span class="lastbuy-bt">大牌惠购</span>
        <br />
        <span class="lastbuy-btpay">汇大牌，享好价</span>
      </div>
      <div class="lastbuy-bot-item-two-img">
        <img src="@static/home/cp.png" alt />
        <img src="@static/home/cp.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ControlArray: {
      type: Array
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.lastbuy {
  width: 94%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  .lastbuy-item {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    .hot-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: inline-block;
        padding: 0 6px;
      }
      .hot-goods-time {
        flex: 1;
        height: 18px;
        display: flex;
        padding-top: 2px;
        align-items: center;
        border-radius: 9px;
        color: #ff8000;
        font-size: 11px;
        border:1px solid rgba(255, 128, 0, 1);
        div {
          height: 18px;
          line-height: 18px;
          color: #ffffff;
          padding: 0 10px;
          border-radius: 9px;
          margin-right: 6px;
          border: none;
          margin-left: -1px;
          background: rgba(255, 128, 0, 1);
        }
      }
    }
  }
  .lastbuy-item-left-con {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .lastbuy-bot-item {
      width: 32%;
      height: auto;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding: 0 6px;
      .lastbuy-bot-item-img {
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .iconrenminbi_o {
        font-size: 12px;
      }
      .newPay {
        font-size: 12px;
        color: #ff8000;
        .iconrenminbi_o {
          font-size: 12px;
        }
      }
      .oldPay {
        font-size: 12px;
        color: #9f9f9f;
        margin-left: -5px;
        text-decoration: line-through;
        .iconrenminbi_o {
          font-size: 6px;
        }
      }
    }
  }
  .lastbuy-bot-item-two:nth-child(3), 
  .lastbuy-bot-item-two:nth-child(4) {
    padding-top: 6px;
  }
  .lastbuy-bot-item-two {
    width: 50%;
    height: 44%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .lastbuy-bot-item-title {
      padding-left: 6px;
      .lastbuy-bt {
        color: #333333;
        flex-wrap: Medium;
      }
      .lastbuy-btpay {
        font-size: 12px;
        flex-wrap: Medium;
        color: #08a7d1;
      }
    }
    .lastbuy-bot-item-two-img {
      width: 100%;
      height: 72.5px;
      display: flex;
      justify-content: space-between;
      img {
        width: 48%;
        height: 100%;
      }
    }
  }
}
</style>