<template>
  <div class="topcon">
    <Swipe :autoplay="3000" class="swiperCon">
      <SwipeItem v-for="(image, index) in images" :key="index" class="swiperCon-item">
        <img v-lazy="image" />
      </SwipeItem>
    </Swipe>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from 'vant';
export default {
  components: {
    Swipe, SwipeItem
  },
  data() {
    return {
      images: [
        'https://img.alicdn.com/simba/img/TB1EKkuxrPpK1RjSZFFSuu5PpXa.jpg',
        'https://gw.alicdn.com/tfs/TB1HRfFqHj1gK0jSZFOXXc7GpXa-640-200.jpg'
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.topcon {
  width: 100%;
  height: auto;
  background: linear-gradient(-90deg,rgba(255,128,0,1),rgba(255,174,0,1));
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  .swiperCon {
    width: 92%;
    height: 140px;
    margin: 0 auto;
    border-radius: 10px;
    .swiperCon-item {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      img {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 160px;
        border-radius: 10px;
      }
    }
  }
}
</style>
